<template>
  <div>
    <h5></h5>
    <table style="margin: 0 auto" cellpadding="5" cellspacing="5">
      <tr>
        <td>
          <div class="left">企业名称：</div>
        </td>
        <td>
          <div class="right">
            {{companyName}}
            <!-- <el-input v-model="companyName" :disabled="true" size="small"></el-input> -->
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="left">所在行业：</div>
        </td>
        <td>
          <div class="right">
            <div style="width: 65%" class="select-school">
                <i-select style="width: 65%" v-model="schoolsOrHospital" v-if="company_type >= '3'" disabled>
                  <i-option value="3">学校</i-option>
                  <i-option value="4">医院</i-option>
                  <i-option value="5">其他</i-option>
                </i-select>
                <selectTree
                  v-else
                  :disable="true"
                  :isGb="true"
                  ref="selectTreegb"
                  placeholder="请选择企业所在行业"
                  :isActive="isAct"
                  :defaultIndeusName="industry_name"
                  :defaultIndusCode="indusCode"
                />
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="left">上传方式：</div>
        </td>
        <td>
         
          <div class="right">
            <el-select v-model="modeWay">
              <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- 单选框改为上面的下拉框 -->
            <!-- <RadioGroup v-model="modeWay" @on-change="onDelUploadFile">
              <Radio label="00" v-if="companyShow">智能报表上传</Radio>
              <Radio label="01">固定模板上传</Radio>
              <Radio label="02" v-if="companyShow">OCR识别</Radio>
            </RadioGroup> -->
              <div class="d-temp" v-show="modeWay == '01'">
              <!-- <el-cascader
                v-model="value"
                :options="options"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange"
                style="width: 100px"
                v-show="(company_type != 1 && (!company_type || company_type == 0))"
              ></el-cascader> -->
              <el-button plain @click="onDownloadTmp">下载模板</el-button>
            </div>
            <el-tooltip style="" placement="bottom-start">
              <div slot="content">
                <span v-if="companyShow">1、智能报表上传：可上传任意格式财务报表，进行识别解析。<br /></span>
                <span v-if="companyShow">2、</span><span v-else>1、</span>固定模板报表上传：需下载模板，根据模板格式进行上传。<br />
                <span v-if="companyShow">若智能报表上传解析失败，可使用固定模板报表上传方式。</span>
              </div>
              <i
                style="margin-left: 15px; width: 14px; height: 14px; cursor: pointer;color:#2b85e4"
                class="el-icon-warning"
              ></i>
            </el-tooltip>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <div class="left">上传报表：</div>
        </td>
        <td>
          <div class="right">
            <div class="uploader pointer">
              <el-button
               
               plain
               icon="el-icon-upload"
                @click="ocrUp"
                style="cursor: default"
                :style="uploadFileName === '' ? 'color: #1989fa' : ''"
              >
                {{ uploadFileName === "" ? "选择文件" : uploadFileName }}
              </el-button>

              <input
                v-if="modeWay != '02'"
                type="file"
                ref="upload"
                @change="handleUploadFile"
                placeholder
                :accept="`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,${
                  modeWay == '02'
                    ? 'application/vnd.ms-excel, image/*, application/pdf'
                    : ''
                }`"
              />
            </div>
             <i
             v-if="uploadFileName"
              @click="onDelUploadFile"
                style="margin-left: 15px; width: 14px; height: 14px; cursor: pointer;color:#2b85e4"
                class="el-icon-delete"
              ></i>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as sesstion from "../store/localStorage";
import Bus from "../eventBus";
import FillCompanyDataTable from "@/components/FillCompanyDataTable";
import { readExcel, postFinancialReport, uploadreports } from "@/api";
import { ocrUp } from "@/api/dataManager";
import { buildSheet, s2ab, saveAs, excelHandle, formatExcelDataToApiJSON } from "@/utils";
// import xlsx from "xlsx";
const VM = require("@/components/VM.js").default;

export default {
  components: {
    FillCompanyDataTable,
    selectTree: require("@/components/selectTree/selectTree.vue").default,
  },
  props: {
    companyCode: String,
    file_type: String,
    isAct: Boolean,
  },

  data() {
    return {
      values:"智能报表上传",
      option:[
         {
          value: "00",
          label: "智能报表上传",
        },
        {
          value: "01",
          label: "固定模板上传",
        },
        {
          value: "02",
          label: "OCR识别",
        },
      ],
      // options: [
      //   {
      //     value: "一般企业-三年模板.xlsx",
      //     label: "一般企业-三年模板.xlsx",
      //   },
      //   {
      //     value: "一般企业-四年模板.xlsx",
      //     label: "一般企业-四年模板.xlsx",
      //   },
      //   {
      //     value: "一般企业-五年模板.xlsx",
      //     label: "一般企业-五年模板.xlsx",
      //   },
      // ],
      // value: "一般企业-三年模板.xlsx",
      modeWay: "00",
      companyName: sessionStorage.companyName || "",
      fileTypeList: [],
      fileType: "",
      sensitive: "",
      savafile: false,
      isWebInput: false,
      startYear: 2016,
      endYear: 2018,
      reportTime: "00",
      report_type: "0",
      reportType: "00",
      // 是否正在生成模板
      isBuildingTmp: false,
      // 模板结构
      allData: {
        zcfzb: {},
        lrb: {},
        xjllb: {},
      },
      uploadFileName: "",
      uploadFile: null,
      subjectNatureArr: ["一般企业", "小微企业", "金融机构", "事业单位"],
      schoolsOrHospitals: {
        3: "学校",
        4: "医院",
        5: "其他"
      },
      subjectNature: "",

      company_type: 0,
      downName: {},
      reportTimeArr: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
      },
      postExcel: {},
      reportFlag: '',
      schoolsOrHospital: "",
    };
  },

  mounted() {
    // Bus.$on("filetype", (data) => {
    //   console.log(data);
    // });
    // this.fileType = sesstion.getLocalData("filetype");
    // console.log()
    // getfileType({
    //   headers: {
    //     Authorization:
    //       'Bearer ' + this.$store.state.isLogin.loginStore.auth_token,
    //   },
    // }).then(({ data }) => {
    //   // console.log(data)
    //   if (data.Status == "1") {
    //     this.fileTypeList = data.Data;
    //   }
    // });
  },

  computed: {
    year() {
      var result = {
        start: new Date().getFullYear(),
      };
      result.end = result.start + 2;
      if (this.startDate) {
        result.start = this.startDate.getFullYear();
        result.end = result.start + 2;
      }
      return result;
    },
    companyShow() {
      if (this.company_type == 3 || this.company_type == 4 || this.company_type == 5) {
        return false;
      } else {
        return true
      }
    }
  },
  created() {
    this.company_type = this.$route.query.company_type;
    this.schoolsOrHospital = this.company_type;
    VM.FillCompanyStepTwo = this;
    this.$VM.FillCompanyStepTwo = this;
    if (this.company_type == 3 || this.company_type == 4 || this.company_type == 5) {
      this.options = [
        {
          value: "事业单位财报模板.xlsx",
          label: "事业单位财报模板.xlsx",
        },
      ];
      this.value = "事业单位财报模板.xlsx";
      this.modeWay = "01";
    }
  },
  methods: {
    handleChange() {},
    onDelUploadFile() {
      this.$refs.upload.value = "";
      this.uploadFileName = "";
      this.uploadFile = null;
      this.isWebInput = false;
    },
    setSubjectNature(company_type) {
      this.company_type = company_type;
      if (company_type >= 3) {
        this.subjectNature = `${this.subjectNatureArr[3]} (${this.schoolsOrHospitals[company_type]})`;
      } else {
        this.subjectNature = `${this.subjectNatureArr[company_type]}`;
      }
    },
    getUpData(allData) {
      // this.company_type = 0;
      formatExcelDataToApiJSON(
        allData,
        this.startYear,
        this.endYear,
        this.company_type
      ).then((postExcel) => {
        // console.log(postExcel);
        this.postExcel = postExcel;
      });
    },

    open() {
      this.$confirm("您是否将会继续上传文件?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.onDelUploadFile();
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    oldUp(e) {
      this.$loadingShow();
      this.uploadFileName = e.target.files[0].name;
      var formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("company_type", this.company_type);
      formData.append("company_code", sessionStorage.company_code);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      // this.uploadFile = e.target.files[0]
      uploadreports(formData, config)
        .then((res) => {
          // console.log(res.data);

          if (res.data.Status == 0) {
            this.$message.error(res.data.Message);
            this.reportFlag = res.data.Message
            this.$loadingHide();

            return 0;
          }
          if (res.data) {
            this.reportFlag = ''
            this.postExcel = res.data;
          } else {
            this.$message.error("上传失败");
          }

          this.$loadingHide();
        })
        .catch((e) => {
          this.$loadingHide();
        });
    },
    ocrUp(e) {
      if (this.modeWay == "02") {
        ocrUp({ company_code: sessionStorage.company_code }).then(({ data }) => {
          location.href = data.jump_url;
        });
      }
    },

    handleUploadFile(e) {
      if (this.modeWay == "01") return this.oldUp(e);

      sesstion.removeLocalData("FZZCTableData");
      sesstion.removeLocalData("LRTableData");
      sesstion.removeLocalData("XJLLTableData");
      this.uploadFileName = e.target.files[0].name;

      var form = new FormData();
      form.append("excel", e.target.files[0], e.target.files[0].name);
      form.append("report_type", this.report_type);
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token" + this.$store.state.isLogin.loginStore.auth_token,
        },
      };

      this.uploadFile = e.target.files[0];

      this.$loadingShow();
      this.$axios
        .post(`${window.BASE_URL}/upload/multiple-fs/recognize/`, form, config)
        .then(({ data }) => {
          this.$loadingHide();
          if (!data.message) {
            sesstion.setLocalData("fileTableData", data);
            this.sensitive = 3;

            this.$emit("sensitive", this.sensitive);
            this.reportFlag = ''
          } else {
            this.reportFlag ="抱歉，您上传的报表未能解析成功，我们已知道这个问题，努力优化支持中。(" +
                data.message +
                ")"
            this.$message({
              duration: 6000,
              // showClose: true,
              type: "error",
              message:
                "抱歉，您上传的报表未能解析成功，我们已知道这个问题，努力优化支持中。(" +
                data.message +
                ")",
            });
          }
        })
        .catch((err) => {
          this.$loadingHide();

          this.$message({
            duration: 6000,
            // showClose: true,
            type: "error",
            message:
              "抱歉，您上传的报表未能解析成功，我们已知道这个问题，努力优化支持中。(上传失败)",
          });
        });
    },
    saveData() {
      if(this.reportFlag){
        this.$message.error(this.reportFlag);
        return false
      }
      // return this.savafile
      if (!this.uploadFileName)
        return this.$Modal.error({
          title: "提示",
          content: "请先上传文件",
        });
      if (!this.postExcel.report) {
        return this.$Modal.error({
          title: "提示",
          content: "上传文件有误",
        });
      }
      if (this.modeWay == "01") {
        return new Promise((resolve, reject) => {
          if (!this.postExcel.report) {
            this.$Modal.error({
              title: "提示",
              content: "请先上传文件",
            });
          }
          postFinancialReport({
            company_code: sessionStorage.company_code,
            company_type: this.company_type,
            ...this.postExcel,
          })
            .then(({ data }) => {
              if (data.Status === "1") {
                this.$Message.info(data.Message);
              } else {
                this.$Message.warning(data.Message);
              }
              resolve();
            })
            .catch((e) => {
              this.$Modal.error({
                title: "提示",
                content: "此文件模板与指定模板不一致,请下载指定的报表模版",
              });
            });
        });
      }

      return new Promise((resolve, reject) => {
        resolve(this.sensitive);
      });
    },

    // 下载模板
    onDownloadTmp() {
      // this.isBuildingTmp = true
      this.$loadingShow();
      // 首先读取模板
      // var fileName =this.company_type === "1" ? "小微企业报表模板.xlsx" : this.value;
      if(this.company_type === "1"){
        window.open(`/static/excel/${this.company_type}/` + '小微企业报表模板.xlsx');
      }
      if (this.company_type >= "3") {
        window.open(`/static/excel/5/事业单位财报模板.xlsx`);
      } else {
        let a = document.createElement('a');
        a.href = `/static/excel/0/一般企业-三年模板.xlsx`;
        a.download = '一般企业-三年模板.xlsx';
        a.click();
        let b = document.createElement('a');
        b.href = `/static/excel/0/一般企业-四年模板.xlsx`;
        b.download = '一般企业-四年模板.xlsx';
        b.click();
        let c = document.createElement('a');
        c.href = `/static/excel/0/一般企业-五年模板.xlsx`;
        c.download = '一般企业-五年模板.xlsx';
        c.click();
        // window.open(`/static/excel/0/一般企业-三年模板.xlsx`)
        // window.open(`/static/excel/0/一般企业-四年模板.xlsx`)
        // window.open(`/static/excel/0/一般企业-五年模板.xlsx`)
      }
      // this.readFinancialExcelBasic()
      setTimeout(() => {
        this.$loadingHide();
      }, 3000);
    },
    readFinancialExcelBasic() {
      this.isBuildingTmp = true;

      return readExcel(`static/excel/${this.company_type}/template.xlsx`).then(
        (workbook) => {
          excelHandle.needWorkbook(
            workbook,
            this.startYear,
            this.endYear,
            this.company_type
          );
          setTimeout(() => {
            xlsx.writeFile(workbook, `${this.downName[this.company_type]}.xlsx`);
            this.$loadingHide();
          }, 1000);
        }
      );
    },
    formatExcel(sheet) {
      var range = xlsx.utils.decode_range(sheet["!ref"]);
      var result = {};
      for (let i = 2; i <= range.e.r; i++) {
        let addr = xlsx.utils.encode_col(range.s.c) + xlsx.utils.encode_row(i);
        result["" + (i - 1)] = {
          row: i - 1,
          text: sheet[addr].v,
          value: {},
        };
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
h5 {
  text-align: center;
  margin-top: 30px;
}
.left {
  width: 170px;
  padding: 10px;
  text-align: right;
  color: rgb(81, 90, 110);
}
.right {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 387px;
  color: rgb(81, 90, 110);
}
.ivu-date-picker {
  border: 1px solid #dcdee2;
  border-radius: 4px;
}
.select-y {
  width: 100px;
  margin-right: 5px;
  &:last-child {
    margin-left: 5px;
  }
}
.select {
  // width: 80px;
  border-radius: 4px;
  // border: 1px solid #bbbbbb;
  padding: 2px;
  padding-right: 20px;
  margin-right: 8px;
  cursor: pointer;
}
.select-disable {
  background: #ccc;
  color: #fff;
}
.selected {
  border: 1px solid #4a81fd;
  background-image: url("../assets/img/selected.png");
  background-position: 102% 100%;
  background-repeat: no-repeat;
  background-size: 20px 16px;
}
.icon-wenhao {
  font-size: 14px;
  color: #bbbbbb;
}
label {
  margin-bottom: 0 !important;
  margin-left: 4px;
}
.d-temp {
  color: #1172fe;
  margin: 0 10px;
  cursor: pointer;
  .el-button{
    border: 1px solid #1172fe;
    color: #1172fe;
  }
}
.upload-tip {
  padding: 4px;
}
.uploader {
  width: 240px;
  
  position: relative;
  overflow: hidden;
  .el-button{
    border: 1px solid #1172fe;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
