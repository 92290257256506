<template>
  <div>
    <div class="table">
      <div class="tr header" ref="detailsHeader">
        <span class="th">科目</span>
        <span class="th">行次</span>
        <span class="th">{{endYear}}</span>
        <span class="th" v-for="year in (endYear - startYear)" :key="endYear - year">{{endYear - year}}</span>
      </div>
      <div class="body">
        <div class="tr" ref="detailsItem">
          <span>货币资金</span>
          <span>1</span>
          <span>
            <input placeholder="请输入金额" v-model="zcfz.hbzj['' + endYear]"/>
          </span>
          <span v-for="year in (endYear - startYear)" :key="endYear - year">
            <input placeholder="请输入金额" v-model="zcfz.hbzj['' + (endYear - year)]"/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xlsx from 'xlsx'
export default {
  props: {
    startYear: Number,
    endYear: Number
  },
  data () {
    return {
      zcfz: {
        hbzj: {}
      }
    }
  },
  mounted () {
    this.readExcel()
  },
  methods: {
    readExcel () {
      this.$axios.get('static/excel/financial-statements-5in1-template.xlsx', {
        responseType:'arraybuffer'
      }).catch(err => {
        console.error(err)
      }).then(res => {
        var data = new Uint8Array(res.data)
        var workbook = xlsx.read(data, {type: 'array'})
        return workbook
      }).then(workbook => {
        console.info('workbook', workbook)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.table{
  position: relative;
  flex: 1;
  border: solid 1px rgb(223, 230, 233);
  .tr{
    display: flex;
    &:nth-child(even) {
      background: rgb(239, 243, 247);
    }
    &:last-child{
      border-bottom: none;
    }
    span{
      &:first-child{
        width: 200px;
      }
      &:nth-child(2){
        width: 60px;
        text-align: center;
      }
      padding: 4px 10px;
      width: 110px;
      border-right: 1px solid rgb(223, 230, 233);
      &:last-child{
        border-right: none;
      }
      input{
        width: 100px;
        background: transparent;
      }
    }
  }
  .header{
    // width: 600px;
    font-weight: bold;
    background: rgb(239, 243, 247);
    .th{
      padding: 10px;
      width: 110px;
      border-right: 1px solid rgb(223, 230, 233);
      &:last-child{
        border-right: none;
      }
      &:first-child{
        width: 200px;
      }
      &:nth-child(2){
        width: 60px;
      }
    }
  }
  .body{
    max-height: 220px;
    overflow: auto;
    // width: 600px;
    color: #999;
  }
}
</style>
