<template>
  <div>
    <div class="wraps">
      <div class="kinds">
        <div
          v-for="(sheet, i) in sheetNames"
          :class="{kind: true, selected: kind === i}"
          @click="kind = i"
          :key="i">{{sheet}}</div>
      </div>
      <div class="table-wrap">
        <div class="i-table" v-if="!isLoadingTemp">
          <!-- <fill-company-data-table-z-c-f-z v-if="kind === 0" :startYear="startYear" :endYear="endYear"></fill-company-data-table-z-c-f-z> -->
          <div class="tr header" ref="detailsHeader">
            <span class="th">科目</span>
            <span class="th">行次</span>
            <span class="th">{{startYear}}</span>
            <span class="th" v-for="year in (endYear - startYear)" :key="startYear +  year">{{startYear + year}}</span>
          </div>
          <div class="body" v-if="!isWebInput">
            <div
              v-if="kind === 0"
              class="tr" ref="detailsItem" v-for="(row, i) in allData.zcfzb" :key="i">
              <span v-html="row['项目名']"></span>
              <span>{{row['行次']}}</span>
              <span>
                <input type="number" readonly="readonly" v-model="row[startYear + '年']"/>
              </span>
              <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                <input type="number" readonly="readonly" v-model="row[(startYear +  year) + '年']"/>
              </span>
            </div>
            <div
              v-if="kind === 1"
              class="tr" ref="detailsItem" v-for="(row, i) in allData.lrb" :key="i">
              <span v-html="row['项目名']"></span>
              <span>{{row['行次']}}</span>
              <span>
                <input type="number" readonly="readonly" v-model="row[startYear + '年']"/>
              </span>
              <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                <input type="number" readonly="readonly" v-model="row[(startYear +  year) + '年']"/>
              </span>
            </div>
            <div
              v-if="kind === 2"
              class="tr" ref="detailsItem" v-for="(row, i) in allData.xjllb" :key="i">
              <span v-html="row['项目名']"></span>
              <span>{{row['行次']}}</span>
              <template v-if="row.row !== 1 && row.row !== 12 && row.row !== 25">
                <span>
                  <input type="number" readonly="readonly" v-model="row[startYear + '年']"/>
                </span>
                <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                  <input type="number" readonly="readonly" v-model="row[(startYear +  year) + '年']"/>
                </span>
              </template>
            </div>
          </div>
          <div class="body" v-else>
            <div
              v-if="kind === 0"
              class="tr" ref="detailsItem" v-for="(row, i) in webInputData.zcfzb" :key="i">
              <span v-html="row.text"></span>
              <span v-html="row.row"></span>
              <template v-if="i === '18'
                || i === '45'
                || i === '46'
                || i === '60'
                || i === '69'
                || i === '70'
                || i === '94'
                || i === '95'">
                <span>
                  <input type="number" readonly="readonly" placeholder="0" v-model="zcfzb['' + i].value[startYear + '年']"/>
                </span>
                <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                  <input type="number" readonly="readonly" placeholder="0" v-model="zcfzb['' + i].value[(startYear + year) + '年']"/>
                </span>
              </template>
              <template v-else>
                <span>
                  <input type="number" placeholder="请输入金额" v-model="row.value[startYear + '年']"/>
                </span>
                <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                  <input type="number" placeholder="请输入金额" v-model="row.value[(startYear +  year) + '年']"/>
                </span>
              </template>
            </div>
            <div
              v-if="kind === 1"
              class="tr" ref="detailsItem" v-for="(row, i) in webInputData.lrb" :key="i">
              <span v-html="row.text"></span>
              <span v-html="row.row"></span>
              <!-- <span>
                <input type="number" placeholder="请输入金额" v-model="row.value[startYear + '年']"/>
              </span>
              <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                <input type="number" placeholder="请输入金额" v-model="row.value[(startYear +  year) + '年']"/>
              </span> -->
              <template v-if="i === '14'
                || i === '18'
                || i === '20'">
                <span>
                  <input type="number" readonly="readonly" placeholder="0" v-model="lrb['' + i].value[startYear + '年']"/>
                </span>
                <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                  <input type="number" readonly="readonly" placeholder="0" v-model="lrb['' + i].value[(startYear + year) + '年']"/>
                </span>
              </template>
              <template v-else>
                <span>
                  <input type="number" placeholder="请输入金额" v-model="row.value[startYear + '年']"/>
                </span>
                <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                  <input type="number" placeholder="请输入金额" v-model="row.value[(startYear +  year) + '年']"/>
                </span>
              </template>
            </div>
            <div
              v-if="kind === 2"
              class="tr" ref="detailsItem" v-for="(row, i) in webInputData.xjllb" :key="i">
              <span v-html="row.text"></span>
              <span v-html="row.row"></span>
              <!-- <span>
                <input type="number" placeholder="请输入金额" v-model="row.value[startYear + '年']"/>
              </span>
              <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                <input type="number" placeholder="请输入金额" v-model="row.value[(startYear +  year) + '年']"/>
              </span> -->
              <template v-if="i === '7'
                || i === '12'
                || i === '13'
                || i === '20'
                || i === '25'
                || i === '26'
                || i === '31'
                || i === '35'
                || i === '36'
                || i === '40'">
                <span>
                  <input type="number" readonly="readonly" placeholder="0" v-model="xjllb['' + i].value[startYear + '年']"/>
                </span>
                <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                  <input type="number" readonly="readonly" placeholder="0" v-model="xjllb['' + i].value[(startYear + year) + '年']"/>
                </span>
              </template>
              <template v-else-if="row.row !== 1 && row.row !== 12 && row.row !== 25">
                <span>
                  <input type="number" placeholder="请输入金额" v-model="row.value[startYear + '年']"/>
                </span>
                <span v-for="year in (endYear - startYear)" :key="startYear +  year">
                  <input type="number" placeholder="请输入金额" v-model="row.value[(startYear +  year) + '年']"/>
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FillCompanyDataTableZCFZ from '@/components/FillCompanyDataTableZCFZ'
import { readExcel, postFinancialReport } from '@/api'
import { formatExcelDataToApiJSON } from '@/utils'
// import xlsxjs from 'xlsx'
const VM = require('@/components/VM.js').default
export default {
  props: {
    // 上传的文件
    uploadFile: File,
    startYear: Number,
    endYear: Number,
    companyCode: String,
    reportTime: String,
    reportType: String
  },
  components: {
    FillCompanyDataTableZCFZ
  },
  data () {
    return {
      isWebInput: false,
      kind: 0,
      sheetNames: [
        '资产负债表',
        '利润表',
        '现金流量表'
      ],
      isLoadingTemp: true,
      // 作为上传文件和推给API的数据载体
      allData: {
        zcfzb: [],
        lrb: [],
        xjllb: []
      },
      // 网页输入
      webInputData: {
        zcfzb: {
          1: {}
        },
        lrb: {
          1: {}
        },
        xjllb: {
          1: {}
        }
      }
    }
  },
  computed: {
    zcfzb () {
      var result = {
        18: {row: '16', value: {}},
        45: {row: '43', value: {}},
        46: {row: '44', value: {}},
        60: {row: '58', value: {}},
        69: {row: '67', value: {}},
        70: {row: '68', value: {}},
        94: {row: '92', value: {}},
        95: {row: '93', value: {}}
      }
      for (let year = this.startYear; year <= this.endYear; year++) {
        result['18'].value[year + '年'] = this.rowSum(this.webInputData.zcfzb, 3, 12, year) +
          this.rowSum(this.webInputData.zcfzb, 15, 17, year)
        result['45'].value[year + '年'] = this.rowSum(this.webInputData.zcfzb, 19, 24, year) +
          this.rowSum(this.webInputData.zcfzb, 28, 34, year) +
          this.rowSum(this.webInputData.zcfzb, 36, 39, year) +
          this.rowSum(this.webInputData.zcfzb, 42, 43, year)
        result['46'].value[year + '年'] = this.webInputData.zcfzb['18'].value[year + '年'] +
          this.webInputData.zcfzb['45'].value[year + '年']
        result['60'].value[year + '年'] = this.rowSum(this.webInputData.zcfzb, 47, 59, year)
        result['69'].value[year + '年'] = this.rowSum(this.webInputData.zcfzb, 61, 68, year)
        result['70'].value[year + '年'] = result['60'].value[year + '年'] + result['69'].value[year + '年']
        result['94'].value[year + '年'] = this.webInputData.zcfzb['71'].value[year + '年'] +
          this.webInputData.zcfzb['79'].value[year + '年'] +
          this.webInputData.zcfzb['82'].value[year + '年'] +
          this.webInputData.zcfzb['84'].value[year + '年'] +
          this.webInputData.zcfzb['88'].value[year + '年'] +
          this.webInputData.zcfzb['90'].value[year + '年'] +
          this.webInputData.zcfzb['93'].value[year + '年'] -
          this.webInputData.zcfzb['83'].value[year + '年']
        result['95'].value[year + '年'] = result['70'].value[year + '年'] +
          result['94'].value[year + '年']        
      }
      console.info('zcfzb', result)
      return result
    },
    lrb () {
      var result = {
        '14': {row: '12', value: {}},
        '18': {row: '16', value: {}},
        '20': {row: '18', value: {}}
      }
      for (let year = this.startYear; year <= this.endYear; year++) {
        result['14'].value[year + '年'] = this.webInputData.lrb['3'].value[year + '年'] -
          (this.rowSum(this.webInputData.lrb, 4, 8, year) +
          this.webInputData.lrb['10'].value[year + '年']) +
          this.webInputData.lrb['11'].value[year + '年'] +
          this.webInputData.lrb['12'].value[year + '年']
        result['18'].value[year + '年'] = result['14'].value[year + '年'] +
          this.webInputData.lrb['15'].value[year + '年'] -
          this.webInputData.lrb['16'].value[year + '年']
        result['20'].value[year + '年'] = result['18'].value[year + '年'] -
          this.webInputData.lrb['19'].value[year + '年']
      }
      console.info('lrb', result)
      return result
    },
    xjllb () {
      var result = {
        7: {row: '5', value: {}},
        12: {row: '10', value: {}},
        13: {row: '11', value: {}},
        20: {row: '18', value: {}},
        25: {row: '23', value: {}},
        26: {row: '24', value: {}},
        31: {row: '29', value: {}},
        35: {row: '33', value: {}},
        36: {row: '34', value: {}},
        40: {row: '38', value: {}}
      }
      for (let year = this.startYear; year <= this.endYear; year++) {
        result['7'].value[year + '年'] = this.rowSum(this.webInputData.xjllb, 4, 6, year)
        result['12'].value[year + '年'] = this.rowSum(this.webInputData.xjllb, 8, 11, year)
        result['13'].value[year + '年'] = this.webInputData.xjllb['7'].value[year + '年'] -
          this.webInputData.xjllb['12'].value[year + '年']
        result['20'].value[year + '年'] = this.rowSum(this.webInputData.xjllb, 15, 19, year)
        result['25'].value[year + '年'] = this.rowSum(this.webInputData.xjllb, 21, 24, year)
        result['26'].value[year + '年'] = this.webInputData.xjllb['20'].value[year + '年'] -
          this.webInputData.xjllb['25'].value[year + '年']
        result['31'].value[year + '年'] = this.rowSum(this.webInputData.xjllb, 28, 30, year)
        result['35'].value[year + '年'] = this.rowSum(this.webInputData.xjllb, 32, 34, year)
        result['36'].value[year + '年'] = this.webInputData.xjllb['31'].value[year + '年'] -
          this.webInputData.xjllb['35'].value[year + '年']
        result['40'].value[year + '年'] = this.webInputData.xjllb['38'].value[year + '年'] +
          this.webInputData.xjllb['39'].value[year + '年']
      }
      console.info('xjllb', result)
      return result
    }
  },
  mounted () {
    VM.FillCompanyDataTable = this
    console.info('companyCode', this.companyCode)
    if (this.uploadFile !== null && this.uploadFile) {
      this.isWebInput = false
      this.formatUploadFile()
    } else {
      this.isWebInput = true
      this.readFinancialExcel()
    }
  },
  methods: {
    rowSum (data, startRow, endRow, year) {
      console.info(data, startRow, endRow, year)
      var result = 0
      for (let i = startRow; i <= endRow; i++) {
        if (data['' + i].value[year + '年'] !== '' && typeof data['' + i].value[year + '年'] !== 'undefined') {
          result += parseFloat(data['' + i].value[year + '年'])
        } 
      }
      return result
    },
    formatUploadFile () {
      this.isLoadingTemp = true
      var reader = new FileReader()
      reader.onload = file => {
        
        var data = new Uint8Array(file.target.result)
        var workbook = xlsxjs.read(data, {type: 'array'})
        console.info('upload workbook', workbook)
        this.allData.zcfzb = this.formatSheet(workbook.Sheets['资产负债表'])
        this.allData.lrb = this.formatSheet(workbook.Sheets['利润表'])
        this.allData.xjllb = this.formatSheet(workbook.Sheets['现金流量表'])
        console.info('allData', this.allData)
        this.isLoadingTemp = false
      }
     
      reader.readAsArrayBuffer(this.uploadFile)
    },
    saveData () {
      return new Promise((resolve, reject) => {
        if (this.isWebInput) this.formatWebInputToAllData(this.webInputData)
        console.info('allData', this.allData)
        formatExcelDataToApiJSON(this.allData, this.startYear, this.endYear).then(postExcel => {
          console.info('postExcel', postExcel)
          postFinancialReport({
            company_code: this.companyCode,
            start_year: this.startYear,
            report_time: this.reportTime,
            report_type: this.reportType,
            report: postExcel
          }).then(({data}) => {
            if (data.Status === '1') {
              resolve()
            } else {
              reject(data.Message)
            }
          })
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * 将网页输入转换为excel上传格式
     */
    formatWebInputToAllData (webInputData) {
      for (let key in webInputData.zcfzb) {
        if (this.zcfzb[key]) {
          this.zcfzb[key].text = webInputData.zcfzb[key].text
          webInputData.zcfzb[key] = this.zcfzb[key]
        }
        var item = {
          '行次': (key - 2) + '',
          '项目名': webInputData.zcfzb[key].text,
        }
        for (let year = this.startYear; year <= this.endYear; year++) {
          item[year + '年'] = webInputData.zcfzb[key].value[year + '年']
        }
        this.allData.zcfzb.push(item)
      }
      for (let key2 in webInputData.lrb) {
        if (this.lrb[key2]) {
          this.lrb[key2].text = webInputData.lrb[key2].text
          webInputData.lrb[key2] = this.lrb[key2]
        }
        var item = {
          '行次': (key2 - 2) + '',
          '项目名': webInputData.lrb[key2].text,
        }
        for (let year = this.startYear; year <= this.endYear; year++) {
          item[year + '年'] = webInputData.lrb[key2].value[year + '年']
        }
        this.allData.lrb.push(item)
      }
      for (let key3 in webInputData.xjllb) {
        if (this.xjllb[key3]) {
          this.xjllb[key3].text = webInputData.xjllb[key3].text
          webInputData.xjllb[key3] = this.xjllb[key3]
        }
        var item = {
          '行次': (key3 - 2) + '',
          '项目名': webInputData.xjllb[key3].text,
        }
        for (let year = this.startYear; year <= this.endYear; year++) {
          item[year + '年'] = webInputData.xjllb[key3].value[year + '年']
        }
        this.allData.xjllb.push(item)
      }
    },
    readFinancialExcel () {
      this.isLoadingTemp = true
      readExcel('static/excel/financial-statements-5in1-template.xlsx').then(workbook => {
        console.info('workbook', workbook)
        var sheet = workbook.Sheets['资产负债表']
        this.webInputData.zcfzb = this.formatSheetInRows(sheet)
        sheet = workbook.Sheets['利润表']
        this.webInputData.lrb = this.formatSheetInRows(sheet)
        sheet = workbook.Sheets['现金流量表']
        this.webInputData.xjllb = this.formatSheetInRows(sheet)
        this.isLoadingTemp = false
        console.info('webInputData', this.webInputData)
      })
    },
    formatSheetInRows (sheet) {
      var range = xlsxjs.utils.decode_range(sheet['!ref'])
      var result = {}
      for (let i = 2; i <= range.e.r; i++) {
        let addr = xlsxjs.utils.encode_col(range.s.c) + xlsxjs.utils.encode_row(i)
        result['' + (i + 1)] = {
          row: i - 1,
          text: sheet[addr].h,
          value: {}
        }
        for (let year = this.startYear; year <= this.endYear; year++) {
          result['' + (i + 1)].value[year + '年'] = 0
        }
      }
      return result
    },
    formatSheet (sheet) {
      var rows = xlsxjs.utils.sheet_to_json(sheet, {range: sheet['!ref'].replace('A1', 'A2'), raw: true})
      console.info('rows',rows)
      // rows.map(row => {
      //   for (let year = this.startYear; year <= this.endYear; year++) {
      //     row[year + '年'] = ''
      //   }
      //   return row
      // })
      return rows
    }
  }
}
</script>

<style lang="less" scoped>
.wraps{
  display: flex;
  align-items: flex-start;
}
.kinds{
  border: solid 1px rgb(216,232, 252);
  padding: 10px;
  width: 180px;
}
.kind{
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  &:after{
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background: #ccc;
    margin-left: 5px;
    border-radius: 2px;
  }
}
.selected{
  color: #57a3f3;
  display: flex;
  align-items: center;
  &:after{
    display: block;
    width: 4px;
    height: 16px;
    border-radius: 2px;
    background: #57a3f3;
  }
}
.table-wrap{
  position: relative;
  margin: 0 20px;
  flex: 1;
  // overflow: hidden;
  overflow-x: scroll;
  height: 300px;
}
.i-table{
  position: absolute;
  top: 0;
  bottom: 0;
  border: solid 1px rgb(223, 230, 233);
  .tr{
    display: flex;
    &:nth-child(even) {
      background: rgb(239, 243, 247);
    }
    &:last-child{
      border-bottom: none;
    }
    span{
      padding: 4px 10px;
      width: 110px;
      border-right: 1px solid rgb(223, 230, 233);
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child{
        width: 200px;
      }
      &:nth-child(2){
        width: 60px;
        text-align: center;
      }
      &:last-child{
        border-right: none;
      }
      input{
        width: 100px;
        background: transparent;
      }
    }
  }
  .header{
    // width: 600px;
    // overflow: auto;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    height: 40px;
    font-weight: bold;
    background: rgb(239, 243, 247);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3);
    .th{
      padding: 10px;
      width: 110px;
      border-right: 1px solid rgb(223, 230, 233);
      &:last-child{
        border-right: none;
      }
      &:first-child{
        width: 200px;
      }
      &:nth-child(2){
        width: 60px;
      }
    }
  }
  .body{
    position: absolute;
    top: 40px;
    bottom: 0;
    overflow-y: auto;
    // width: 600px;
    color: #999;
  }
}
</style>

