<template>
  <div>

  </div>
  <!-- <div class="data-manager-wrap bgc_w pm-4" style="background:#fff;padding-bottom: 30px;min-height:800px">
    <div style="margin-bottom: 20px; ">
      <div class="title">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        上传报表
      </div>
    </div>
    <div >
      <fill-company-step-two
      ref="2"
      :companyCode="companyCode"
      :companyName="companyName"
      @sensitive="sensitiveRemind"
      :isAct="false"
      style="margin: 0 auto"
    ></fill-company-step-two>
    <div class="bts" style="padding-left: 40%; margin-top: 20px">
      <el-button @click="go">
       取消
      </el-button>
      <el-button type="primary" @click="onNextTwo(2)">确定</el-button>
    </div>
    </div>
    
  </div> -->
</template>

<script>
export default {
  components: {
    FillCompanyStepTwo: require("@/components/FillCompanyStepTwo").default,
  },
  mounted(){
    if (this.$route.path == '/CenterIndex/data-manager/upReport') {
      this.$router.push('/database/data-manager')
    }
  },
  methods: {
    go(){
      this.$router.push('/database/data-manager')
    },
    onNextTwo() {
      this.$refs["2"]
        .saveData()
        .then((resp) => {
          this.$router.go(-1);
        })
        .catch((err) => {});
    },
    sensitiveRemind() {
      this.$router.push({
        name: "intelligenceUpReoprt",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.data-manager-wrap{
  border-radius: 8px;
  overflow: hidden;
  margin-left: 45px;
  margin-right: 45px;
}
.title {
  padding: 20px;
  font-size: 20px;
  font-weight: 550;
  background: #fff;
  border-bottom: 1px solid rgb(240, 237, 237);
}
.bts{
  .el-button{
    width: 120px;
  }
}
</style>
